.tmx-btn-primary {
  @include button-variant($color-white, $tmx-color-red-primary, $tmx-color-red-dark);
  text-transform: uppercase;
}

.tmx-btn-success {
  @include button-variant($color-white, $tmx-color-blue-primary, $tmx-color-blue-dark);
  text-transform: uppercase;
}

.tmx-btn-back {
  @include button-variant($color-white, $tmx-color-gray-primary, $tmx-color-gray-dark);
}

.tmx-btn-default {
  @include button-variant($color-white, $tmx-color-gray-primary, $tmx-color-gray-dark);
}

.input-group .input-group-btn button,
.form-group .input-group-btn button {
  height: 34px;
}

.tmx-btn-military-statement {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);

  white-space: normal;
  text-align: left;
  margin-bottom: 10px;
  padding: 6px 3px;

  &.active {
    @include button-variant($color-white, $tmx-color-blue-primary, $tmx-color-blue-dark);
  }

  .fa-ul {
    margin-bottom: 0;
  }
}

.tmx-btn-military-statement:last-child {
  margin-bottom: 0;
}
