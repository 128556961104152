@mixin tmx-banner($size) {
  padding: $size;
  margin-bottom: $size;
  border-bottom: 1px solid $tmx-color-divider;
  text-align: center;

  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }

  .tmx-logo {
    display: inline-block;
  }

  .tmx-logo-va {
    display: inline-block;
  }
}

.tmx-banner-sm {
  @include tmx-banner(20px);

  .tmx-logo {
    background-size: 250px 55px;
    width: 250px;
    height: 55px;
    @include img-retina("../img/TMOnline-Logo@1x.png", "../img/TMOnline-Logo@1x.png", 250px, 55px);
  }

  .tmx-logo-va {
    background-size: 250px 55px;
    width: 250px;
    height: 55px;
    @include img-retina("../img/TMLoans-Logo@1x.jpg", "../img/TMLoans-Logo@1x.jpg", 250px, 55px);
  }
}

.tmx-banner-lg {
  @include tmx-banner(30px);

  .tmx-logo {
    background-size: 500px 109px;
    width: 500px;
    height: 109px;
    @include img-retina("../img/TMOnline-Logo@1x.png", "../img/TMOnline-Logo@2x.png", 500px, 109px);
  }

  .tmx-logo-va {
    background-size: 500px 109px;
    width: 500px;
    height: 109px;
    @include img-retina("../img/TMLoans-Logo@1x.jpg", "../img/TMLoans-Logo@2x.jpg", 500px, 109px);
  }
}
