.tmx-block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.25;
  filter: alpha(opacity=25);
  background-color: $tmx-color-blue-primary;
}

.tmx-block-ui-message-container {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  height: 0;
  text-align: center;
  z-index: 10001;
}

.tmx-block-ui-message {
  display: inline-block;
  text-align: left;
  background-color: $tmx-color-blue-darker;
  color: $color-white;
  padding: 20px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  // needed for IE

  filter: alpha(opacity=100);
}
