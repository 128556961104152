.tmx-legal-document {
  font-family: 'Source Sans Pro', sans-serif;

  header {
    text-align: center;
  }

  p {
    text-align: inherit;
  }

  h5 {
    display: inline;
    font-weight: bold;
  }

  ul {
    padding-left: $padding-small-horizontal * 2;
  }
}
