@mixin tmx-footer($size) {
  margin-top: $size;
  padding-top: $size;
  padding-bottom: $size;
  border-top: 1px solid $tmx-color-divider;

  .tmx-copyright {
    text-align: center;
    font-size: smaller;
  }

  .tmx-disclaimer {
    font-size: smaller;

    .tmx-disclaimer-title {
      font-size: large;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      margin-top: 1em;
    }
  }

  .tmx-license-link {
    font-size: smaller;
    text-align: center;
    margin-top: 1em;
  }
}

.tmx-footer-sm {
  @include tmx-footer(20px);
}

.tmx-footer-lg {
  @include tmx-footer(30px);
}
