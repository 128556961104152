body {
  color: $tmx-color-text;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

@media (max-width: $screen-md-max) {
  .container {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
}

.tmx-link {
  color: $tmx-color-link;
  text-decoration: underline;

  &:focus,
  &:visited {
    color: $tmx-color-link;
  }

  &:hover {
    color: $tmx-color-link-hover;
  }
}

.tmx-divider {
  border-top: 1px solid $tmx-color-divider;
}
