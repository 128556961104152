// Standard Colors
$color-white: #ffffff;
$color-gray-lightest: #f5f5f5;
$color-gray-medium: #cccccc;
$color-black: #000000;

// TitleMax (TMX) Colors
$tmx-color-red-darkest: #a61220;
$tmx-color-red-darker: #be1524;
$tmx-color-red-dark: #d61829;

$tmx-color-red-primary: #b80413;

$tmx-color-red-light: #ef3142;
$tmx-color-red-lighter: #f14857;
$tmx-color-red-lightest: #f35f6c;

$tmx-color-blue-darkest: #00396c;
$tmx-color-blue-darker: #00417c;
$tmx-color-blue-dark: #00498b;

$tmx-color-blue-primary: #00529b;

$tmx-color-blue-light: #1963a5;
$tmx-color-blue-lighter: #3274af;
$tmx-color-blue-lightest: #4c85b9;

$tmx-color-navy-primary: #002157;

$tmx-color-light-blue-primary: #74bad4;

$tmx-color-gray-darkest: #313131;
$tmx-color-gray-darker: #383838;
$tmx-color-gray-dark: #3f3f3f;

$tmx-color-gray-primary: #464646;

$tmx-color-gray-light: #585858;
$tmx-color-gray-lighter: #6a6a6a;
$tmx-color-gray-lightest: #7d7d7d;

// TitleMax Themes
$tmx-color-divider: #acacac;//$color-gray-medium;
$tmx-color-text: $tmx-color-gray-primary;
$tmx-color-header: $tmx-color-blue-primary;
$tmx-color-link: $tmx-color-blue-primary;
$tmx-color-link-hover: $tmx-color-blue-lightest;
$tmx-color-required: #cc0101;
