// Margins: .margin-x, .margin-left-x, .margin-bottom-x, etc.
$margin-types: left, right, top, bottom;
$margin-increments: 0, 5, 10, 15, 20, 30, 50;

// Generate .margin-x
@each $margin-increment in $margin-increments {
  .margin-#{$margin-increment} {
    margin: #{$margin-increment}px;
  }
}

// Generate .margin-type-x
@each $margin-type in $margin-types {
  @each $margin-increment in $margin-increments {
    .margin-#{$margin-type}-#{$margin-increment} {
      margin-#{$margin-type}: #{$margin-increment}px;
    }
  }
}

// Padding: .padding-x, .padding-left-x, etc.
$padding-types: left, right, top, bottom;
$padding-increments: 0, 5, 10, 20, 30, 50;

// Generate .padding-x
@each $padding-increment in $padding-increments {
  .padding-#{$padding-increment} {
    padding: #{$padding-increment}px;
  }
}

// Generate .padding-type-x
@each $padding-type in $padding-types {
  @each $padding-increment in $padding-increments {
    .padding-#{$padding-type}-#{$padding-increment} {
      padding-#{$padding-type}: #{$padding-increment}px;
    }
  }
}

// Horizontal Rules: .hr-x
$hr-increments: 0, 5, 10, 15, 20;

@each $hr-increment in $hr-increments {
  .hr-#{$hr-increment} {
    margin-top: #{$hr-increment}px;
    margin-bottom: #{$hr-increment}px;
  }
}

// Widths
.width-fixed-1 {
  width: 1px;
}

.width-fixed-100 {
  width: 100px;
}

.width-fixed-200 {
  width: 200px;
}

.width-fixed-400 {
  width: 400px;
}

.width-fixed-650 {
  width: 650px;
}

.width-fixed-700 {
  width: 700px;
}

.width-percent-25 {
  width: 25%;
}

.width-auto {
  width: auto;
}

// Text
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-bold {
  font-weight: bold;
}

// Display
.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

// Alignment
.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

// Relative indentations
$indent-width: 15px;

@for $i from 1 through 4 {
  .indent-#{$i} {
    margin-left: $i * $indent-width;
  }
}
