h1,
h2,
h3,
h4,
h5,
h6 {
  &.tmx-header {
    color: $tmx-color-header;
  }

  &.tmx-title {
    margin-top: 0;
  }
}

.tmx-message-required {
  font-size: smaller;
  color: black;
  margin-right: 10px;
}

.tmx-text-required {
  color: $tmx-color-required;
}

@include text-emphasis-variant('.tmx-text-primary', $tmx-color-red-primary);

@include text-emphasis-variant('.tmx-text-success', $tmx-color-blue-primary);
