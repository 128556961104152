.tmx-disclosure {
  margin-bottom: 5px;
}

.tmx-warning {
  margin-bottom: 30px;
}

/* anti-spam honeypot */
.tmx-hilite, .tmx-hilite-large, .tmx-hilite-small {
  height: 1em !important;
  width: 1em !important;
  overflow: scroll !important;
  -ms-scrollbar-arrow-color: white !important;
  scrollbar-arrow-color: white !important;
  margin-top: -1em !important;
  margin-left: -1em !important;

  .col-xs-12 {
    top: 2em !important;
    left: 2em !important;
  }


}

//The below code makes the anti-spam honeypot hidden from view, by matching the color of the Visible Scrollbar to the White Background.
.tmx-hilite::-webkit-scrollbar, .tmx-hilite-large::-webkit-scrollbar, .tmx-hilite-small::-webkit-scrollbar {  width: 10px;}
.tmx-hilite::-webkit-scrollbar-track, .tmx-hilite-large::-webkit-scrollbar-track, .tmx-hilite-small::-webkit-scrollbar-track {  background: 	#FFFFFF;}
.tmx-hilite::-webkit-scrollbar-thumb, .tmx-hilite-large::-webkit-scrollbar-thumb, .tmx-hilite-small::-webkit-scrollbar-thumb {  background: 	#FFFFFF;}
.tmx-hilite::-webkit-scrollbar-thumb:hover, .tmx-hilite-large::-webkit-scrollbar-thumb:hover, .tmx-hilite-small::-webkit-scrollbar-thumb:hover {  background: 	#FFFFFF;}

