.tmx-panel-default {
  @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);

  .panel-footer {
    background-color: transparent;
  }
}

.tmx-panel-danger {
  @include panel-variant($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);

  .panel-footer {
    background-color: transparent;
  }
}

.tmx-panel-review {
  @include panel-variant($tmx-color-blue-darker, $color-white, $tmx-color-blue-primary, $tmx-color-blue-darker);

  address:last-child {
    margin-bottom: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .tmx-edit {
    float: right;

    font-size: smaller;
    color: $color-white;
    opacity: .75;

    &:hover {
      opacity: 1;
    }

    i {
      margin-right: 5px;
    }
  }
}

.tmx-panel-success {
  @include panel-variant($tmx-color-blue-darker, $color-white, $tmx-color-blue-primary, $tmx-color-blue-darker);
}

.tmx-panel-disclosure {
  @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);

  .panel-footer {
    background-color: transparent;
  }
}

.tmx-disclosure-closed {
  max-height: 450px;
  overflow: hidden;
}

.tmx-panel-disclosure-danger {
  @include panel-variant($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);

  .panel-footer {
    background-color: transparent;
  }
}

.tmx-panel-legal {
  font-size: smaller;
  text-align: inherit;
}
